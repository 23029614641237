<template>
  <div class="container">
    <SkeletonAnimate
      :visible="preloader"
    />

    <div class="container__control">
      <ClosePopUpButton
        class="black"
      />
    </div>

    <div class="container__content">
      <h3>
        {{ $t('components.ui.pages.pop-up.withdrawal.withdrawal') }}
      </h3>

      <p>
        {{ $t('components.ui.pages.pop-up.withdrawal.available') }}
      </p>

      <h2>
        $ {{ $format.wallet(_info.balance, ',') }}
      </h2>

      <div class="container__content__inputs">
        <div class="container__content__inputs__item">
          <SelectInput
            class="flex-width"
            style="margin: 0;"
            :placeholder="`${$t('components.ui.pages.pop-up.withdrawal.wallet')}*`"
            :list="_getWallets"
            v-model="form.wallet"
            :errors="form.errors.wallet"
          />

          <button
            class="container__content__inputs__item-button"
            @click="$pop.up('withdrawalMethods')"
          >
            <svg
              width="20" height="20"
              style="min-width: 20px; min-height: 20px;"
              viewBox="0 0 20 20" fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10 2V18M2 10H18" stroke="#2F57E9" stroke-width="3" stroke-linecap="round"/>
            </svg>
          </button>
        </div>

        <div class="container__content__inputs__item">
          <TextInput
            style="margin: 0;"
            :placeholder="$t('components.ui.pages.pop-up.withdrawal.amount')"
            fixed="0"
            floor
            v-model="form.amount"
          />

          <button
            class="container__content__inputs__item-button"
            @click="max()"
          >
            <svg
              width="28" height="8"
              style="min-width: 28px; min-height: 8px;"
              viewBox="0 0 28 8" fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9.5099 0V8H7.96984V2.17313L5.31322 8H4.18384L1.54006 2.17313V8H0V0H2.13042L4.7742 5.83881L7.41798 0H9.5099Z" fill="#2F57E9"/>
              <path d="M19.4947 8H17.7621L16.915 6.07761H13.0777L12.2435 8H10.5494L14.1686 0H15.8627L19.4947 8ZM14.9771 1.69552L13.6424 4.77612H16.3375L14.9771 1.69552Z" fill="#2F57E9"/>
              <path d="M24.8172 3.91642L28 8H26.0364L23.8932 5.13433L21.7371 8H19.8248L22.982 3.9403L19.9018 0H21.8782L23.9188 2.73433L26.0236 0H27.8332L24.8172 3.91642Z" fill="#2F57E9"/>
            </svg>
          </button>
        </div>
      </div>

      <div class="container__content__info">
        <div
          v-if="
            form.wallet?.currency?.show_rate
              && (form.wallet?.currency?.rate > 1 || form.wallet?.currency?.rate < 1)
          "
          class="container__content__info__item"
          style="text-transform: uppercase;"
        >
          <svg
            width="14" height="14"
            style="min-width: 14px; min-height: 14px;"
            viewBox="0 0 14 14" fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8.69534 10.2933L8.5815 10.7731C8.23997 10.9121 7.96715 11.0178 7.764 11.0906C7.56062 11.1635 7.32432 11.1998 7.05508 11.1998C6.64162 11.1998 6.32002 11.0954 6.09066 10.888C5.86129 10.6797 5.74657 10.4159 5.74657 10.0958C5.74657 9.97184 5.75489 9.84439 5.7722 9.71452C5.78965 9.5845 5.8174 9.43808 5.85537 9.27441L6.2822 7.71693C6.32017 7.56779 6.35245 7.42648 6.3783 7.29285C6.40451 7.16005 6.41715 7.03786 6.41715 6.92787C6.41715 6.72897 6.3772 6.58984 6.29768 6.51162C6.21816 6.43363 6.06641 6.39403 5.84113 6.39403C5.73079 6.39403 5.61739 6.41225 5.50179 6.44748C5.38568 6.48286 5.28645 6.51704 5.20312 6.54881L5.31726 6.06865C5.59687 5.95128 5.86414 5.85078 6.11979 5.76736C6.37545 5.68372 6.61701 5.64186 6.8455 5.64186C7.25612 5.64186 7.57297 5.74417 7.79547 5.9488C8.01797 6.15357 8.12919 6.41902 8.12919 6.74613C8.12919 6.81381 8.12189 6.93306 8.10619 7.10351C8.09085 7.27433 8.0623 7.43084 8.02067 7.57321L7.59568 9.12444C7.56084 9.24904 7.52952 9.39147 7.50228 9.55175C7.47416 9.71098 7.46073 9.83264 7.46073 9.91425C7.46073 10.1202 7.50527 10.2609 7.59458 10.3356C7.68455 10.4104 7.8395 10.4476 8.0596 10.4476C8.16292 10.4476 8.28049 10.4287 8.41113 10.3916C8.54141 10.3544 8.63641 10.3218 8.69534 10.2933ZM8.80312 3.78076C8.80312 4.05103 8.70432 4.28185 8.50577 4.47164C8.30773 4.66211 8.06909 4.75742 7.78992 4.75742C7.50987 4.75742 7.27065 4.66211 7.07034 4.47164C6.8704 4.28178 6.77022 4.05103 6.77022 3.78076C6.77022 3.51102 6.8704 3.27982 7.07034 3.08762C7.27028 2.89572 7.50995 2.7998 7.78992 2.7998C8.06902 2.7998 8.30773 2.89594 8.50577 3.08762C8.70447 3.27982 8.80312 3.51109 8.80312 3.78076Z" fill="#00B307"/>
            <rect x="1" y="1" width="12" height="12" rx="2" stroke="#00B307"/>
          </svg>

          {{
            form.wallet?.currency?.rate > 1
              ? `${$format.wallet(form.wallet?.currency?.rate)} USD - 1 ${form.wallet?.currency?.slug}`
                : `${$format.wallet(1 / form.wallet?.currency?.rate)} ${form.wallet?.currency?.slug} - 1 USD`
          }}
        </div>

        <div
          v-if="form.wallet?.currency?.min"
          class="container__content__info__item"
        >
          <svg
            width="14" height="14"
            style="min-width: 14px; min-height: 14px;"
            viewBox="0 0 14 14" fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8.69534 10.2933L8.5815 10.7731C8.23997 10.9121 7.96715 11.0178 7.764 11.0906C7.56062 11.1635 7.32432 11.1998 7.05508 11.1998C6.64162 11.1998 6.32002 11.0954 6.09066 10.888C5.86129 10.6797 5.74657 10.4159 5.74657 10.0958C5.74657 9.97184 5.75489 9.84439 5.7722 9.71452C5.78965 9.5845 5.8174 9.43808 5.85537 9.27441L6.2822 7.71693C6.32017 7.56779 6.35245 7.42648 6.3783 7.29285C6.40451 7.16005 6.41715 7.03786 6.41715 6.92787C6.41715 6.72897 6.3772 6.58984 6.29768 6.51162C6.21816 6.43363 6.06641 6.39403 5.84113 6.39403C5.73079 6.39403 5.61739 6.41225 5.50179 6.44748C5.38568 6.48286 5.28645 6.51704 5.20312 6.54881L5.31726 6.06865C5.59687 5.95128 5.86414 5.85078 6.11979 5.76736C6.37545 5.68372 6.61701 5.64186 6.8455 5.64186C7.25612 5.64186 7.57297 5.74417 7.79547 5.9488C8.01797 6.15357 8.12919 6.41902 8.12919 6.74613C8.12919 6.81381 8.12189 6.93306 8.10619 7.10351C8.09085 7.27433 8.0623 7.43084 8.02067 7.57321L7.59568 9.12444C7.56084 9.24904 7.52952 9.39147 7.50228 9.55175C7.47416 9.71098 7.46073 9.83264 7.46073 9.91425C7.46073 10.1202 7.50527 10.2609 7.59458 10.3356C7.68455 10.4104 7.8395 10.4476 8.0596 10.4476C8.16292 10.4476 8.28049 10.4287 8.41113 10.3916C8.54141 10.3544 8.63641 10.3218 8.69534 10.2933ZM8.80312 3.78076C8.80312 4.05103 8.70432 4.28185 8.50577 4.47164C8.30773 4.66211 8.06909 4.75742 7.78992 4.75742C7.50987 4.75742 7.27065 4.66211 7.07034 4.47164C6.8704 4.28178 6.77022 4.05103 6.77022 3.78076C6.77022 3.51102 6.8704 3.27982 7.07034 3.08762C7.27028 2.89572 7.50995 2.7998 7.78992 2.7998C8.06902 2.7998 8.30773 2.89594 8.50577 3.08762C8.70447 3.27982 8.80312 3.51109 8.80312 3.78076Z" fill="#00B307"/>
            <rect x="1" y="1" width="12" height="12" rx="2" stroke="#00B307"/>
          </svg>

          {{ $t('components.ui.pages.pop-up.withdrawal.min') }} $ {{ $format.wallet(form.wallet?.currency?.min, ',') }}
        </div>

        <div
          v-if="form.wallet?.currency?.max"
          class="container__content__info__item"
        >
          <svg
            width="14" height="14"
            style="min-width: 14px; min-height: 14px;"
            viewBox="0 0 14 14" fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8.69534 10.2933L8.5815 10.7731C8.23997 10.9121 7.96715 11.0178 7.764 11.0906C7.56062 11.1635 7.32432 11.1998 7.05508 11.1998C6.64162 11.1998 6.32002 11.0954 6.09066 10.888C5.86129 10.6797 5.74657 10.4159 5.74657 10.0958C5.74657 9.97184 5.75489 9.84439 5.7722 9.71452C5.78965 9.5845 5.8174 9.43808 5.85537 9.27441L6.2822 7.71693C6.32017 7.56779 6.35245 7.42648 6.3783 7.29285C6.40451 7.16005 6.41715 7.03786 6.41715 6.92787C6.41715 6.72897 6.3772 6.58984 6.29768 6.51162C6.21816 6.43363 6.06641 6.39403 5.84113 6.39403C5.73079 6.39403 5.61739 6.41225 5.50179 6.44748C5.38568 6.48286 5.28645 6.51704 5.20312 6.54881L5.31726 6.06865C5.59687 5.95128 5.86414 5.85078 6.11979 5.76736C6.37545 5.68372 6.61701 5.64186 6.8455 5.64186C7.25612 5.64186 7.57297 5.74417 7.79547 5.9488C8.01797 6.15357 8.12919 6.41902 8.12919 6.74613C8.12919 6.81381 8.12189 6.93306 8.10619 7.10351C8.09085 7.27433 8.0623 7.43084 8.02067 7.57321L7.59568 9.12444C7.56084 9.24904 7.52952 9.39147 7.50228 9.55175C7.47416 9.71098 7.46073 9.83264 7.46073 9.91425C7.46073 10.1202 7.50527 10.2609 7.59458 10.3356C7.68455 10.4104 7.8395 10.4476 8.0596 10.4476C8.16292 10.4476 8.28049 10.4287 8.41113 10.3916C8.54141 10.3544 8.63641 10.3218 8.69534 10.2933ZM8.80312 3.78076C8.80312 4.05103 8.70432 4.28185 8.50577 4.47164C8.30773 4.66211 8.06909 4.75742 7.78992 4.75742C7.50987 4.75742 7.27065 4.66211 7.07034 4.47164C6.8704 4.28178 6.77022 4.05103 6.77022 3.78076C6.77022 3.51102 6.8704 3.27982 7.07034 3.08762C7.27028 2.89572 7.50995 2.7998 7.78992 2.7998C8.06902 2.7998 8.30773 2.89594 8.50577 3.08762C8.70447 3.27982 8.80312 3.51109 8.80312 3.78076Z" fill="#00B307"/>
            <rect x="1" y="1" width="12" height="12" rx="2" stroke="#00B307"/>
          </svg>

          {{ $t('components.ui.pages.pop-up.withdrawal.max') }} $ {{ $format.wallet(form.wallet?.currency?.max, ',') }}
        </div>

        <div class="container__content__info__item red">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="#DA2828"/>
            <path d="M7.40638 9.33731L7.31496 4H8.70924L8.62924 9.33731H7.40638ZM7.25781 10.3881H8.74353V12H7.25781V10.3881Z" fill="white"/>
          </svg>

          {{ $t('components.ui.pages.pop-up.withdrawal.correct-address') }}
        </div>
      </div>

      <BlueButton
        :text="$t('components.ui.pages.pop-up.withdrawal.send')"
        :loading="loading"
        :disabled="
          loading || currencyToNumber < form.wallet?.currency?.min ||
          currencyToNumber > form.wallet?.currency?.max || form.wallet === null ||
          _info.balance < currencyToNumber
        "
        @click="submit()"
      />
    </div>
  </div>
</template>

<script>
import SkeletonAnimate from '@/ui/preloader/SkeletonAnimatePreloader.vue'
import ClosePopUpButton from '@/ui/buttons/ClosePopUpButton.vue'
import SelectInput from '@/ui/inputs/Select.vue'
import TextInput from '@/ui/inputs/Wallet.vue'
import BlueButton from '@/ui/buttons/BlueButton.vue'

export default {
  components: {
    SkeletonAnimate,
    ClosePopUpButton,
    SelectInput,
    TextInput,
    BlueButton
  },
  data () {
    return {
      loading: false,
      preloader: true,

      form: {
        wallet: null,
        amount: '$ 10',

        errors: {
          wallet: []
        }
      }
    }
  },
  computed: {
    _info () {
      return this.$store.state.user.balance
    },
    _getWallets () {
      const wallets = this._info?.wallets || []
      var parse = []

      for (const i in wallets) {
        parse.push({
          id: wallets[i].id,
          label: `${wallets[i].currency.name} | ${wallets[i].address}`,
          currency: {
            ...wallets[i].currency
          }
        })
      }

      return parse
    },
    currencyToNumber () {
      // eslint-disable-next-line no-useless-escape
      var currency = this.form.amount.slice(1).replace(/[^.\d]+/g, '').replace(/^([^\.]*\.)|\./g, '$1')
      return Number(currency)
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch () {
      try {
        const resp = await this.$axios.get(
          '/private/withdraw')
        if (resp.status === 200) {
          this.$store.commit('USER_SET_BALANCE', resp.data.data)
          // this.info = resp.data.data
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.preloader = false
      }
    },
    async submit () {
      this.loading = true

      try {
        const formData = new FormData()
        formData.append('amount', this.currencyToNumber)
        formData.append('wallet_id', this.form.wallet.id)

        const resp = await this.$axios.post(
          '/private/withdraw', formData)

        if (resp.status === 200) {
          this.$pop.up('withdrawalConfirm')
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
      }
    },
    max () {
      const balance = this._info.balance

      if (balance > 500000) {
        this.form.amount = `$ ${this.$format.wallet(500000, ',')}`
      } else {
        this.form.amount = `$ ${this.$format.wallet(this._info.balance, ',')}`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  width: 100%;
  max-width: 430px;
  padding: 10px;
  background: white;
  border-radius: 15px;

  .container__control {
    display: flex;
    justify-content: flex-end;
  }

  .container__content {
    padding: 0 10px 10px;

    h3 {
      margin-bottom: 30px;
      text-align: center;
      line-height: 100%;
      color: rgb(var(--color-dark));
      font-family: SemiBold;
    }

    p {
      text-align: center;
      font-size: 12px;
      line-height: 100%;
      text-align: center;
      color: rgba(var(--text-color-light-gray));
      font-family: Medium;
    }

    h2 {
      margin: 8px 0;
      line-height: 100%;
      text-align: center;
      color: rgba(var(--text-color-light-gray));
      font-family: SemiBold;
    }

    .container__content__inputs {
      width: 100%;
      margin: 30px 0 20px;

      .container__content__inputs__item {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        &:first-child {
          margin-bottom: 15px;
        }

        .container__content__inputs__item-button {
          height: 46px;
          width: 46px;
          min-width: 46px;
          max-width: 46px;
          margin-left: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(var(--primary-color), .2);
          border-radius: 5px;
        }
      }
    }

    .container__content__info {
      margin: 20px 0;

      .container__content__info__item {
        margin-bottom: 5px;
        padding: 7px;
        display: flex;
        align-items: center;
        background: rgba(var(--color-green), .15);
        border-radius: 5px;
        font-size: 12px;
        line-height: 100%;
        color: rgb(var(--color-green));
        font-family: Medium;

        &:last-child {
          margin: 0;
        }

        &.red {
          background: rgba(218, 40, 40, .1);
          color: #DA2828;
        }

        svg {
          margin-right: 5px;
        }
      }
    }
  }
}

.flex-width {
  width: calc(100% - 56px) !important;
}
</style>
