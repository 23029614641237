<template>
  <div class="ci__ui">
    <div
      class="input-container"
      :class="{
        error: false
      }"
      @click="$refs.input.focus()"
    >
      <input
        type="text"
        ref="input"
        v-model="_value"
      >

      <p
        class="input-container__placeholder"
        :class="{
          active: modelValue && modelValue.length > 0
        }"
      >
        {{ placeholder }}
      </p>

      <div
        v-if="errors.length > 0"
        class="error-icon"
      >
        <img src="@/static/svg/error-input-icon.svg">
      </div>
    </div>

    <p
      v-for="(error, index) in errors"
      :key="index"
      class="ci__ui__error-text"
    >
      {{ $t(error) }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      required: true
    },
    fixed: {
      type: Number,
      default: 2
    },
    floor: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    _value: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  watch: {
    modelValue () {
      // eslint-disable-next-line no-useless-escape
      var toNumber = Number(this._value.slice(1).replace(/[^.\d]+/g, '').replace(/^([^\.]*\.)|\./g, '$1'))

      toNumber = this.floor ? Math.floor(toNumber) : toNumber.toFixed(this.fixed)
      this._value = `$ ${this.$format.wallet(toNumber)}`
    }
  }
}
</script>
