<template>
  <div class="ci__ui">
    <div
      class="input-container flex"
      :class="{
        error: false
      }"
    >
      <div class="input-container__content">
        {{ modelValue ? _eval(modelValue) : '' }}
      </div>

      <div
        class="input-container__arrow"
        :class="{
          active: view
        }"
        @click="view = !view"
      >
        <svg
          width="14" height="9"
          style="min-width: 14px; min-height: 9px;"
          viewBox="0 0 14 9" fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M13 1L7 7L1 1" stroke="#9CA3B8" stroke-width="2" stroke-linecap="round"/>
        </svg>
      </div>

      <p
        class="input-container__placeholder"
        :class="{
          active: modelValue
        }"
      >
        {{ placeholder }}
      </p>

      <div
        v-if="view"
        class="input-container__list"
        v-click-outside="hide"
      >
        <div
          v-for="(item, index) in list"
          :key="index"
          class="input-container__list__item"
          :class="{
            active: item.id === modelValue?.id
          }"
          @click="item.id === modelValue?.id ? null : $emit('update:modelValue', item), hide()"
        >
          {{ _eval(item) }}
        </div>
      </div>
    </div>

    <p
      v-for="(error, index) in errors"
      :key="index"
      class="ci__ui__error-text"
    >
      {{ $t(error) }}
    </p>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3'

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    modelValue: {
      type: String,
      default: null
    },
    list: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      default: 'label'
    },
    placeholder: {
      type: String,
      required: true
    },
    errors: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      view: false,
      select: null
    }
  },
  methods: {
    hide () {
      this.view = false
    },
    _eval (value) {
      // eslint-disable-next-line no-eval
      return eval(`value.${this.label}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.ci__ui {
  &.wrap {
    .input-container {
      height: auto !important;
      min-height: 46px;
      align-items: center;

      .input-container__content {
        max-width: 100%;
        white-space: pre-wrap;
        overflow-wrap: break-word;
        padding-bottom: 20px;
      }

      .input-container__arrow {
        height: 100%;
        min-height: 36px;
      }
    }
  }

  .input-container {
    position: relative;
    justify-content: space-between;
    padding: 5px 0 5px 7px !important;

    .input-container__content {
      position: relative;
      top: 18px;
      width: 100%;
      font-size: 16px;
      line-height: 100%;
      color: rgb(var(--color-dark));
      font-family: Medium;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .input-container__arrow {
      height: 100%;
      border-left: 1px solid rgba(var(--border-line-color));

      &.active {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    .input-container__list {
      position: absolute;
      top: calc(100% + 5px);
      left: 0;
      max-height: 200px;
      width: 100%;
      padding: 4px;
      background: white;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, .1);
      border-radius: 5px;
      overflow-y: auto;
      scrollbar-color: rgba(var(--primary-color), 1) rgba(var(--primary-color), .2);
      scrollbar-width: thin;
      z-index: 2;

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: rgba(var(--primary-color), .2);
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(var(--primary-color), 1);
        border-radius: 4px;
      }

      .input-container__list__item {
        padding: 12px 6px;
        border-radius: 5px;
        font-size: 16px;
        line-height: 100%;
        color: rgb(var(--text-color-light-gray));
        font-family: Medium;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
        transition: .2s;

        &:hover {
          color: rgb(var(--color-dark));
        }

        &.active {
          background: rgba(var(--primary-color), .2);
          color: rgb(var(--primary-color));
        }
      }
    }
  }
}
</style>
